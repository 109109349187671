<template>
  <transition name="modal">
    <div class="modal" v-if="isOpen">
      <div class="modal__backdrop" @click="$emit('close-modal')"></div>
      <div class="modal__window">
        <div class="modal__header">
          <div class="modal__close" @click="$emit('close-modal')">
            <p class="close-text">fechar</p>
            <div class="close-icon" />
          </div>
        </div>
        <div class="modal__body">
          <slot></slot>
        </div>
        <div class="modal__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Modal',
  model: {
    prop: 'isOpen',
    event: 'close-modal'
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";
@import "~@/styles/main.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 300;
  &-enter-active, &-leave-active {
    transition: opacity 0.3s;
  }
  &-enter, &-leave-to {
    opacity: 0;
  }
  &__backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.6);
    display: block;
  }
  &__window {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
    padding: 30px 50px;
    width: 624px;
    max-height: 95%;
    @include respond-to(phone-only) {
      width: 100%;
      max-height: 100%;
      padding: 15px 25px;
    }
    &::-webkit-scrollbar {
      width: 0.5em !important;
      height: 0.5em !important;
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
    background-color: rgba(45,90,160, 0.8) !important;
    border-radius: 3px !important;
      &:hover {
        background-color: rgba(45,90,160, 0.9) !important;
      }
    }
  }
  &__close {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    vertical-align: middle;
    .close-text {
      line-height: 15px;
      font-size: 14px;
      margin-right: 10px;
      color: #888;
    }
    .close-icon {
      @extend .icon;
      display: inline-block;
      width: 15px;
      height: 15px;
      background-image: url('https://beiramarimoveis.com.br/dev/static/img/close.svg');
    }
  }
  &__header {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
  }
  &__body {
    display: block;
    width: 100%;
    height: 100%;
    //overflow-y: scroll;
  }
  &__footer {
    flex-shrink: 0;
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
    /deep/ *:only-child {
      margin-left: auto;
    }
  }
}
</style>
