<template>
  <div>
    <h2 class="scheduleform__header">Escolha um dia e horário<br>para você visitar o imóvel</h2>
    <ScheduleCarousel />
    <child slot="footer">
      <a class="scheduleform__footercancel"  v-on:click="redirectToBegin()">Cancelar</a>
      <div>
        <ActionButton block
          class="scheduleform__footerconfirm"
          :disabled="!isHourAndDateSelected()"
          @click="onClickContinue">Continuar</ActionButton>
      </div>
    </child>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ScheduleCarousel from './agendamento_sections/ScheduleCarousel'
import ActionButton from '../../components/ui/ActionButton'
import store from '../../stores/store'

export default {
  name: 'ScheduleSchedule',
  store,
  components: {
    ScheduleCarousel,
    ActionButton
  },
  data () {
    return {
      globalOffset: 0,
      continueIsLoading: false
    }
  },
  computed: {
    ...mapGetters('schedule', ['date', 'hour']),
    scheduleDate: {
      get () {
        return this.date
      },
      set (val) {
        this.setDate(val)
      }
    },
    scheduleHour: {
      get () {
        return this.hour
      },
      set (val) {
        this.setHour(val)
      }
    }
  },
  methods: {
    isHourAndDateSelected () {
      if (this.date && this.date !== '' && this.hour && this.hour !== '') {
        return true
      } else {
        return false
      }
    },
    onClickContinue () {
      if (this.isHourAndDateSelected()) {
        store.state.scheduleStep = 'infos'
      }
    },
    redirectToBegin () {
      store.state.openModal = false
      store.state.scheduleStep = 'schedule'
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  @import "~@/styles/variables.scss";
  @import "~@/styles/mixins.scss";
  @import "~@/styles/main.scss";
  .scheduleform{
    &__header{
      font-weight: bold;
      line-height: normal;
      font-size: 28px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #444444;
      padding: 10px 0px;
      margin: 0;
    }
    @include respond-to(phone-only) {
      &__header{
        font-size: 19px;
        padding: 10px 0px;
      }
    }
    &__period{
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #888888;
      margin: 15px 0px;
    }
    &__hourcarousel{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    &__footercancel{
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      color: #888888;
      float: left;
      vertical-align: middle;
      display: inline-block;
      text-transform: capitalize;
      line-height: 52px;
      padding: 0px 49px;
    }
    @include respond-to(phone-only) {
      &__footercancel{
        padding: 0px 30px;
        line-height: 45px;
      }
    }
    &__footerconfirm{
      float: right;
    }
    @include respond-to(phone-only) {
      &__footerconfirm{
        line-height: 45px;
        padding: 0px 30px;
      }
    }
    &__footerconfirm--active{
      background: var(--bg-color);
      color: #ffffff;
      transition: all 0.1s ease-out;
      cursor: pointer;
    }
  }
  .scheduleform__hourcarouselhidden{
    display: none;
  }
</style>
