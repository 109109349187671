<template>
  <div>
    <h2 class="scheduleform__concludeheader">
      Ocorreu um erro!
    </h2>
    <p class="scheduleform__concludedescription">
      Por favor, tente selecionar outro horário
    </p>
    <parent>
      <child slot="footer">
        <a class="scheduleform__footerconclude"
          v-on:click="redirectToBegin()"
        >
          Escolher outro horário
        </a>
      </child>
    </parent>
  </div>
</template>
<script>
import store from '../../stores/store'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ScheduleError',
  store,
  methods: {
    ...mapMutations('schedule', ['setDate', 'setHour', 'setScheduleId']),
    ...mapMutations('property', ['setAvailablehours']),
    redirectToBegin () {
      this.setDate = ''
      this.setHour = ''
      this.setScheduleId = ''
      this.setAvailablehours = ''
      store.state.scheduleStep = 'schedule'
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .scheduleform{
    &__concludeimage{
      width: 80px;
      display: block;
      margin: auto;
    }
    &__concludeheader{
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 38px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #444444;
    }
    &__concludedescription{
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 20px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #888888;
      margin-bottom: 52px;
    }
    &__footerconclude{
      display: block;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 14px;
      text-align: center;
      color: #444444;
      border-top: 2px solid #DDDDDD;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 10px;
      cursor: pointer;
    }
  }
</style>