<template>
  <div class="text-field" :class="{'text-field--disabled': isDisabled, 'text-field--error': !!errors.first(name)}">
    <label class="text-field__label" :for="id"><span>{{labelText}}</span><slot name="extraAction"></slot></label>
    <input class="text-field__input"
           v-validate="validation"
           data-vv-validate-on="change"
           :name="name"
           :type="type"
           :id="id"
           :ref="name"
           :disabled="isDisabled"
           v-mask="maskConfig"
           :value="value"
           @keyup.enter="$emit('enter', id)"
           @input="onInput">
    <div class="error-text" v-if="hasError">{{ errors.first(name) }}</div>
  </div>
</template>
<script>
import { mask, tokens, masker } from 'vue-the-mask2/dist/VueTheMask.common'
import portuguese from '@/definitions/error_messages'

export default {
  name: 'text-field',
  data () {
    return {
      lastValue: null, // avoid unecessary emit when has no change
      display: this.value,
      customTokens: {
        ...tokens,
        ...{ 'H': {pattern: /.*/g} }
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    labelText: String,
    id: {
      type: String,
      default: '0'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    helpText: String,
    mask: {
      type: [String, Array],
      required: false,
      default: function () {
        return 'H'.repeat(256)
      }
    },
    name: {
      type: String,
      required: true
    },
    masked: { // by default emits the value unformatted, change to true to format with the mask
      type: Boolean,
      default: false // raw
    },
    tokens: {
      type: Object,
      default: () => tokens
    },
    validation: {
      type: [String, Object],
      default: ''
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  directives: { mask },
  created () {
    this.$validator.localize('pt_BR', {
      messages: portuguese.messages
    })
  },
  computed: {
    maskConfig () {
      return {
        mask: this.mask,
        tokens: this.customTokens,
        masked: this.masked
      }
    },
    hasError () {
      return !!this.errors.first(this.name)
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  },
  watch: {
    value (newValue) {
      if (newValue !== this.lastValue) {
        this.display = newValue
      }
    },
    masked () {
      this.refresh(this.display)
    }
  },
  methods: {
    updateValue (e) {
      let value = e.target.value
      this.$emit('change', { value: masker(value, this.mask, this.masked, this.customTokens) })
    },
    onInput (e) {
      if (e.isTrusted) return // ignore native event
      this.errors.remove(this.name)
      this.refresh(e.target.value)
    },
    refresh (value) {
      this.display = value
      let currentValue = masker(value, this.mask, this.masked, this.customTokens)
      if (currentValue !== this.lastValue) {
        this.lastValue = currentValue
        this.$emit('input', currentValue)
      }
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";
@import "~@/styles/main.scss";

.error-text {
  color: $negative-color;
  font-size: calc-rem(12px);
  font-style: italic;
  margin-top: 4px;
  margin-left: 5px;
}
.text-field {
  margin-bottom: $form-input-margin-bottom;
  * {
    transition: all 0.1s;
  }
  &__label {
    display: flex;
    justify-content: space-between;
  }
  &__input {
    @extend %typo-input-value;
    font-size: $form-input-font-size;
    display: block;
    -webkit-appearance: none;
    box-sizing: border-box;
    height: $form-input-height;
    width: 100%;
    line-height: $form-input-line-height;
    padding: $form-input-padding-v $form-input-padding-h;
    border: $form-input-border;
    border-radius: $form-input-border-radius;
    background-color: transparent;
    &:focus {
      @include tinting(brand-color, 0.3);
      border-color: var(--brand-color-tint);
    }
  }
  &--disabled & {
    &__label {
      opacity: 0.6;
    }
    &__input {
      @include adjust-opacity(dark-bg-color, 0.05);
      background-color: var(--dark-bg-color-opacity);
      color: $dark-text-color;
    }
  }
  &--error & {
    &__label {
      color: $negative-color;
      font-weight: bold;
    }
    &__input {
      border-color: $negative-color;
    }
  }
}
</style>
