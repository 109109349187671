<template>
  <div class="phone-field">
    <TextField
      v-model="text"
      label-text="Celular"
      :name="'celular'"
      id="celular"
      :validation="{
        required: true,
        regex: /^\(?(?:\d{2})\)?\s?[6-9](?:\d{3}|\d{4})\-?\d{4}$/
      }"
      :mask="['(##) ####-####','(##) #####-####']"
      :masked="true"
    />
    <div
      v-if="errorMsg !== ''"
      class="error-text"
    >
      {{ errorMsg }}
    </div>
  </div>
</template>
<script>
import Axios from 'axios'
import { createHelpers } from 'vuex-map-fields'
import TextField from './TextField'
import { mapGetters } from 'vuex'

const { mapFields } = createHelpers({
  getterType: `user/getField`,
  mutationType: `user/updateField`
})

export default {
  name: 'PhoneField',
  model: {
    props: 'phone',
    event:  'input'
  },
  components: {
    TextField
  },
  props: {
    phone: Object,
    default: () => {}
  },
  data () {
    return {
      text: '',
      valid: false,
      dataPhone: {},
      errorMsg: ''
    }
  },
  watch: {
    text (newVal) {
      this.errorMsg = ''
      this.text = newVal
      this.$emit('input', { text: newVal, valid: this.valid })
      if (newVal && newVal.length >= 14) {
        this.validatePhone(newVal)
      }
    },
    dataPhone (newVal) {
      if (newVal && newVal.email && newVal.email.length) {
        this.$emit('newvalidationemail', newVal)
      }
    }
  },
  computed: {
    ...mapFields({
      userName: 'user.name',
      userPhone: 'user.phone',
      userEmail: 'user.email',
      userId: 'user.userId'
    }),
    ...mapGetters('schedule', ['environment']),
  },
  methods: {
    normalizePhone (phone) {
      return '+55' + phone.match(/\d+/g).join('')
    },
    validatePhone (phone) {
      phone = this.normalizePhone(phone)
      let url = `https://${this.environment}.ruadois.com.br/api/v1/users/phone/${phone}`
      Axios.get(url)
        .then(response => {
          this.valid = true
          this.dataPhone = response.data
          this.userId = response.data.identifier
          this.$emit('newvalidationemail', response.data)
          if (response.data !== {}) {
            this.errorMsg = `Número já cadastrado. Certifique-se de colocar o e-mail "${response.data.email}" abaixo`
          } else {
            this.errorMsg = ''
          }
        })
        .catch(() => {
          this.$emit('newvalidationemail', null)
          this.valid = false
        })
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";
@import "~@/styles/main.scss";

.phone-field {
  margin-bottom: 20px;
  .error-text {
    font-size: calc-rem(12px);
    font-style: italic;
    margin-top: 4px;
    margin-left: 5px;
  }
}
</style>
