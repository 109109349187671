<template>
    <div>
        <h2 class="scheduleform__header">Poderia informar seus dados?</h2>
          <!-- <text-field label-text="Nome"
                      name="nome"
                      id="nome"
                      validation="required|alpha_spaces|min:3"
                      :type="'text'"
                      v-model="userName"></text-field>
          <text-field label-text="E-mail"
                      name="email"
                      id="email"
                      validation="required|email"
                      :type="'text'"
                      v-model="userEmail"></text-field> -->
          <PhoneField v-model="userPhone" @newvalidationemail="validationPhone = $event" />
          <EmailField v-model="userEmail" @newvalidationemail="validationEmail = $event" :userIdentifierFromPhone="validationPhone && validationPhone.identifier ? validationPhone.identifier : null" />
          <text-field label-text="Nome"
                      name="nome"
                      id="nome"
                      validation="required|alpha_spaces|min:3"
                      :type="'text'"
                      :isDisabled="(!userPhone || !userEmail) || validationPhone || validationEmail"
                      v-model="userName"></text-field>
          <!-- <input type="text">
          <text-field label-text="Celular"
                      name="celular"
                      id="celular"
                      :validation="{ required: true, regex: /^\(?(?:\d{2})\)?\s?[6-9](?:\d{3}|\d{4})\-?\d{4}$/ }"
                      :mask="['(##) ####-####','(##) #####-####']"
                      :masked="true"
                      v-model="userPhone"></text-field> -->
        <parent>
            <child slot="footer">
              <a class="scheduleform__footercancel" v-on:click="redirectToBegin()">Cancelar</a>
              <div>
                <ActionButton block
                  class="scheduleform__footerconfirm"
                  @click="onClickContinue()"
                  :disabled="continueDisabledStatus">Continuar</ActionButton>
              </div>
            </child>
        </parent>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import TextField from '../../components/ui/TextField'
import PhoneField from '../../components/ui/PhoneField'
import EmailField from '../../components/ui/EmailField'
import ActionButton from '../../components/ui/ActionButton'
import store from '../../stores/store'

const { mapFields } = createHelpers({
  getterType: `user/getField`,
  mutationType: `user/updateField`
})

export default {
  name: 'ScheduleInfos',
  store,
  components: {
    ActionButton,
    TextField,
    PhoneField,
    EmailField
  },
  data () {
    return {
      continueIsLoading: false,
      validationEmail: null,
      validationPhone: null
    }
  },
  // created () {
  //   if (!this.date || this.date === '' || !this.hour || this.hour === '') {
  //     store.state.scheduleStep = 'schedule'
  //   }
  // },
  watch: {
    // validationEmail (newVal) {
    //   if (newVal && newVal.length) {
    //     this.userEmail = newVal;
    //   }
    // }
    validationEmail (newVal) {
      if (this.validationPhone && this.validationPhone.identifier && this.validationPhone.identifier === newVal.identifier) {
        this.userName = newVal.name;
      } else {
        this.userName = '';
      }
    },
    validationPhone (newVal) {
      if (this.validationEmail && this.validationEmail.identifier && this.validationEmail.identifier === newVal.identifier) {
        this.userName = newVal.name;
      } else {
        this.userName = '';
      }
    }
  },
  computed: {
    ...mapFields({
      userName: 'user.name',
      userPhone: 'user.phone',
      userEmail: 'user.email',
      userId: 'user.userId'
    }),
    inputsAreFullFilled () {
      return this.userName.length !== 0 && this.userEmail.length !== 0 && this.userPhone.length !== 0
    },
    continueDisabledStatus () {
      return !(this.inputsAreFullFilled && this.errors.items.length === 0)
    },
    ...mapGetters('schedule', ['date', 'hour'])
  },
  methods: {
    ...mapActions('user', ['saveUser']),
    normalizePhone () {
      this.userPhone = '+55' + this.userPhone.text.match(/\d+/g).join('')
    },
    onClickContinue () {
      this.normalizePhone()
      store.state.scheduleStep = 'confirmation'

      // this.continueIsLoading = true
      // this.$validator.validateAll()
      //   .then((result) => {
      //     if (result) {
      //       this.normalizePhone()
      //       this.continueIsLoading = false
      //       store.state.scheduleStep = 'confirmation'
      //     }
      //   })
      //   .catch(() => {
      //   })
    },
    redirectToBegin () {
      store.state.openModal = false
      store.state.scheduleStep = 'schedule'
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  @import "~@/styles/variables.scss";
  @import "~@/styles/mixins.scss";
  @import "~@/styles/main.scss";

  .scheduleform{
    &__header{
      font-weight: bold;
      line-height: normal;
      font-size: 28px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #444444;
      padding: 10px 0px;
      margin: 0;
    }
    &__footercancel{
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      color: #888888;
      float: left;
      vertical-align: middle;
      display: inline-block;
      text-transform: capitalize;
      line-height: 52px;
      padding: 0px 49px;
    }
    @include respond-to(phone-only) {
      &__footercancel{
        padding: 0px 30px;
        line-height: 45px;
      }
    }
    &__footerconfirm{
      float: right;
    }
    @include respond-to(phone-only) {
      &__footerconfirm{
        line-height: 45px;
        padding: 0px 30px;
      }
    }
    &__footerconfirm--active{
      background: var(--bg-color);
      color: #ffffff;
      transition: all 0.1s ease-out;
      cursor: pointer;
    }
  }
</style>
