<template>
  <div v-if="isValid" :style="cssProps">
    <Modal
      class="scheduleform"
      :isOpen="openModal"
      @close-modal="closeModal()"
    >
      <ScheduleSchedule v-if="scheduleStep === 'schedule'"/>

      <ScheduleInfos v-else-if="scheduleStep === 'infos'"/>

      <ScheduleConfirmation v-else-if="scheduleStep === 'confirmation'"/>

      <ScheduleError v-else-if="scheduleStep === 'error'"/>

      <ScheduleObrigado v-else/>
    </Modal>

    <ScheduleWidgetButton
      :buttonColor="currentColor"
      :buttonPosition="currentPosition"
      :heightHide="heightHide"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import store from '../stores/store'
import Axios from 'axios'
import { mapMutations } from 'vuex'
import VeeValidate from 'vee-validate'
import Modal from '../components/ui/Modal'
import ScheduleWidgetButton from './steps_agendamento/ScheduleWidgetButton'
import ScheduleSchedule from './steps_agendamento/ScheduleSchedule'
import ScheduleInfos from './steps_agendamento/ScheduleInfos'
import ScheduleConfirmation from './steps_agendamento/ScheduleConfirmation'
import ScheduleError from './steps_agendamento/ScheduleError'
import ScheduleObrigado from './steps_agendamento/ScheduleObrigado'

Vue.use(VeeValidate)

export default {
  name: 'schedule-widget',
  store,
  components: {
    Modal,
    ScheduleWidgetButton,
    ScheduleSchedule,
    ScheduleInfos,
    ScheduleConfirmation,
    ScheduleError,
    ScheduleObrigado
  },
  data () {
    return {
      isValid: false,
      currentColor: '',
      currentPosition: '',
      heightHide: false,
      externalCompanyCode: true
    }
  },
  props: {
    propertycode: {
      type: String,
      default: ''
    },
    companyid: {
      type: String,
      default: ''
    },
    widgetposition: {
      type: String,
      default: ''
    },
    widgetcolor: {
      type: String,
      default: ''
    },
    externalcompanycode: {
      type: String,
      default: 'true'
    },
    heighthide: {
      type: String,
      default: 'false'
    },
    environment: {
      type: String,
      default: 'homologation'
    },
    origin: {
      type: String,
      default: 'widgettest'
    },
  },
  computed: {
    cssProps() {
      return {
        '--bg-color': this.widgetcolor
      }
    },
    openModal () {
      return store.state.openModal
    },
    scheduleStep () {
      return store.state.scheduleStep
    }
  },
  created () {
    const widget = document.querySelector('schedule-widget')
    let url = ''
    let propertycode = widget.propertycode
    let companyid = widget.companyid

    let env = '';
    if (this.environment === 'homologation') {
      env = 'hmg';
    } else if (this.environment === 'production') {
      env = 'api';
    }
    this.setEnvironment(env);
    this.setOrigin(this.origin);

    if (widget.externalcompanycode === 'true') {
      url = `https://${env}.ruadois.com.br/api/v1/companies/${companyid}/properties/external/${propertycode}`
    } else {
      url = `https://${env}.ruadois.com.br/api/v1/properties/${propertycode}`
    }

    Axios.get(url)
      .then(response => {
        if (response.status === 200 && !response.data.error) {
          if (response.data.isPublic) {
            this.isValid = true
  
            this.setPropertyCode(propertycode)
            this.setCompanyCode(companyid)
            console.log(widget.externalcompanycode)
            console.log(widget.externalcompanycode === 'true')
            this.setActiveExternalCompany(widget.externalcompanycode === 'true')
  
            this.currentPosition = widget.widgetposition
            this.currentColor = widget.widgetcolor
            this.heightHide = widget.heighthide === 'true'
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    ...mapMutations('property', ['setAvailablehours', 'setPropertyCode', 'setCompanyCode', 'setActiveExternalCompany']),
    ...mapMutations('widget', ['setPosition', 'setBrandcolor']),
    ...mapMutations('schedule', ['setEnvironment', 'setOrigin']),
    closeModal () {
      store.state.openModal = false
      store.state.scheduleStep = 'schedule'
      return false
    }
  }
}
</script>
<style lang="scss">
  @import "../styles/variables.scss";
  @import "../styles/mixins.scss";
  @import "../styles/main.scss";

  .modal.scheduleform{
    z-index: 30000000 !important;
  }
  .scheduleform{
    &__header{
      font-weight: bold;
      line-height: normal;
      font-size: 28px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #444444;
      padding: 10px 100px;
      margin: 0;
    }
    &__period{
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #888888;
      margin: 15px 0px;
    }
    &__hourcarousel{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    &__footercancel{
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      color: #888888;
      float: left;
      vertical-align: middle;
      display: inline-block;
      text-transform: capitalize;
      line-height: 52px;
      padding: 0px 49px;
    }
    &__footerconfirm{
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 16px;
      text-align: center;
      color: #FFFFFF;
      background: #E0E0E0;
      border-radius: 5px;
      line-height: 52px;
      padding: 0px 49px;
      float: right;
      cursor: not-allowed;
    }
  }
</style>
