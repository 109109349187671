<template>
  <div class="email-field">
    <TextField
      v-model="text"
      label-text="E-mail"
      :name="'email'"
      id="email"
      :validation="{
        required: true,
        email: true
      }"
      :type="'text'"
    />
    <div
      v-if="errorMsg !== ''"
      class="error-text"
    >
      {{ errorMsg }}
    </div>
    <div
      v-if="this.userIdentifierFromPhone && (this.userIdentifier !== this.userIdentifierFromPhone) && (!errorMsg || errorMsg === '')"
      class="error-text"
    >
      Por favor, insira o e-mail correto vinculado com o telefone celular acima
    </div>
  </div>
</template>
<script>
import Axios from 'axios'
import { createHelpers } from 'vuex-map-fields'
import TextField from './TextField'
import { mapGetters } from 'vuex'

const { mapFields } = createHelpers({
  getterType: `user/getField`,
  mutationType: `user/updateField`
})

export default {
  name: 'EmailField',
  model: {
    props: 'email',
    event:  'input'
  },
  components: {
    TextField
  },
  props: {
    email: Object,
    default: () => {},
    userIdentifierFromPhone: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      text: '',
      valid: false,
      dataEmail: {},
      errorMsg: '',
      userIdentifier: ''
    }
  },
  watch: {
    text (newVal) {
      this.errorMsg = ''
      this.text = newVal
      this.$emit('input', { text: newVal, valid: this.valid })
      if (this.validateInput(newVal)) {
        this.validateEmail(newVal)
      }
    }
  },
  computed: {
    ...mapFields({
      userName: 'user.name',
      userPhone: 'user.phone',
      userEmail: 'user.email',
      userId: 'user.userId'
    }),
    ...mapGetters('schedule', ['environment']),
  },
  methods: {
    validateInput (newVal) {
      return newVal && newVal.length >= 4 && newVal.includes('@')
    },
    validateEmail (email) {
      let url = `https://${this.environment}.ruadois.com.br/api/v1/users/email/${email}`
      Axios.get(url)
        .then(response => {
          this.valid = true
          this.dataPhone = response.data
          this.userIdentifier = response.data.identifier
          this.$emit('newvalidationemail', response.data)
          if (response.data !== {}) {
            this.errorMsg = `E-mail já cadastrado. Se certifique-se de colocar o número "${response.data.phone}" acima`
          } else {
            this.errorMsg = ''
          }
        })
        .catch(() => {
          this.$emit('newvalidationemail', null)
          this.valid = false
        })
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";
@import "~@/styles/main.scss";

.email-field {
  margin-bottom: 20px;
  .error-text {
    font-size: calc-rem(12px);
    font-style: italic;
    margin-top: 4px;
    margin-left: 5px;
  }
}
</style>
