import { render, staticRenderFns } from "./ScheduleObrigado.vue?vue&type=template&id=c8eef90c&scoped=true&"
import script from "./ScheduleObrigado.vue?vue&type=script&lang=js&"
export * from "./ScheduleObrigado.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ScheduleObrigado.vue?vue&type=style&index=0&id=c8eef90c&lang=scss&rel=stylesheet%2Fscss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c8eef90c",
  null
  ,true
)

export default component.exports