<template>
  <div
    class="widgetbutton"
    :class="`${buttonPosition} ${hiddenButton() ? ' widgetbutton--hidden' : ''}`"
    :style="{ 'background-color': buttonColor }"
    @click="setCurrentRoute()"
  >
    <img src="~@/assets/img/icons/calendar-icon.svg" />
    <div class="widgetbutton__text">
      <span class="widgetbutton__title">Agendar visita</span>
    </div>
  </div>
</template>

<script>
import store from "../../stores/store";

export default {
  name: "schedule-widget-button",
  store,
  props: {
    buttonPosition: {
      type: String,
      default: "onright"
    },
    buttonColor: {
      type: String,
      default: "#2A64E6"
    },
    heightHide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHidden: false,
      scrolled: false
    };
  },
  watch: {
    $route(to) {
      if (to.name.includes("Schedule")) {
        document.body.style.overflowY = "hidden";
        document.documentElement.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
        document.documentElement.style.overflowY = "auto";
      }
    },
    heightHide(newVal) {
      this.heightHide = newVal;
    }
  },
  methods: {
    setHidden() {
      this.isHidden = true;
    },
    setNotHidden() {
      this.isHidden = false;
    },
    setCurrentRoute() {
      store.state.openModal = true;
    },
    handleScroll() {
      this.isHidden =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
    },
    hiddenButton() {
      return this.heightHide && this.isHidden;
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/main.scss";
.widgetbutton {
  display: block;
  position: fixed;
  bottom: 28px;
  z-index: 1000000;
  border: none;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 260px;
  height: 70px;
  cursor: pointer;
  transition: right 0.5s ease-in-out;
  @include respond-to(phone-only) {
    right: calc(50vw - 115px) !important;
    width: 235px;
    height: 60px;
    line-height: 25px;
  }
  img,
  i {
    height: 30px;
    width: auto;
    line-height: 25px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 22px;
    padding-left: 29px;
  }
  i {
    font-size: 30px;
    color: #ffffff;
    @include respond-to(phone-only) {
      font-size: 25px;
      color: #ffffff;
      line-height: 15px;
    }
  }
  &__text {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 75px);
    padding-top: 15px;
    padding-left: 11.22px;
  }
  &__title {
    font-style: normal;
    font-weight: bold;
    line-height: 40px;
    font-size: 21px;
    color: #ffffff;
    display: block;
    @include respond-to(phone-only) {
      font-style: normal;
      font-weight: bold;
      line-height: 29px;
      font-size: 18px;
    }
  }
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }
  &__close {
    color: #aaaaaa;
    font-weight: bold;
    position: absolute;
    font-size: 23px;
    top: -12px;
    right: -12px;
    z-index: 1000010;
  }
  .aligntoleft {
    position: absolute;
    color: $brand-color;
    left: -63px;
    font-size: 22px;
    opacity: 0;
    @include respond-to(phone-only) {
      display: none;
    }
  }
  .aligntoright {
    position: absolute;
    color: $brand-color;
    right: -30px;
    font-size: 22px;
    opacity: 0;
    @include respond-to(phone-only) {
      display: none;
    }
  }
}
.widgetbutton.onright {
  right: 28px;
}
.widgetbutton.oncenter {
  right: calc(50vw - 130px);
}
.widgetbutton.onleft {
  right: calc(100vw - 288px);
}
.widgetbutton.onright:hover,
.widgetbutton.oncenter:hover {
  .aligntoleft {
    position: absolute;
    color: $brand-color;
    left: -63px;
    font-size: 22px;
    opacity: 1;
    animation: bounce-on-left 1s infinite;
  }
}
.widgetbutton.onleft:hover,
.widgetbutton.oncenter:hover {
  .aligntoright {
    position: absolute;
    color: $brand-color;
    right: -33px;
    font-size: 22px;
    opacity: 1;
    animation: bounce-on-right 1s infinite;
  }
}
.widgetbutton.widgetbutton--hidden {
  right: -268px !important;
}
@keyframes bounce-on-left {
  0% {
    left: -63px;
  }
  50% {
    left: -73px;
  }
  100% {
    left: -63px;
  }
}
@keyframes bounce-on-right {
  0% {
    right: -33px;
  }
  50% {
    right: -43px;
  }
  100% {
    right: -33px;
  }
}
</style>
