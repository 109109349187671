<template>
  <div>
    <p class="scheduleform__period">
      {{ this.getCurrentMonth() }} / {{ this.getCurrentYear() }}
    </p>

    <carousel
      class="scheduleform__datecarousel"
      :scrollPerPage="true"
      :perPageCustom="[[300, 4], [900, 4], [1200, 6], [1800, 6]]"
      :navigationEnabled="true"
      :paginationEnabled="false"
      navigationNextLabel="<i class='fas fa-chevron-right'></i>"
      navigationPrevLabel="<i class='fas fa-chevron-left'></i>"
    >
      <slide
        v-for="possibleDate in possibleDates"
        :key="possibleDate"
        :navigationClickTargetSize="0"
      >
        <div class="card-day">
          <div
            v-if="isLoading && getDateByOffset(possibleDate) === date"
            class="loading-card"
          >
            <img src="~@/assets/img/icons/loading.svg" class="spinner" />
            <!-- <i class="fas fa-spinner fa-spin fa-3x spinner" /> -->
          </div>

          <compact-data
            v-else
            :id="getDateByOffset(possibleDate)"
            :isAvailable="true"
            :isSelected="isDateActive(getDateByOffset(possibleDate))"
            :label="getWeekDayByOffset(possibleDate)"
            :value="getDateDayByOffset(possibleDate).toString()"
            @click="getDateHours(getDateByOffset(possibleDate), $event)"
          />
        </div>
      </slide>
    </carousel>

    <div
      v-if="!isLoading && date !== '' && availablehours.length === 0"
      class="scheduleform__warning"
    >
      Não há horários disponíveis para esse dia
    </div>

    <div
      v-else
      class="scheduleform__hourcarousel"
    >
      <div
        v-for="availablehour in availablehours"
        :key="availablehour.datetime.slice(11, 16)"
      >
        <compact-hour
          :value="availablehour.datetime.slice(11, 16)"
          :isSelected="isHourActive(availablehour.datetime)"
          :isAvailable="availablehour.availability"
          @click="availablehour.availability ? setDataHour(availablehour) : null"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import CompactData from '../../../components/ui/CompactData'
import CompactHour from '../../../components/ui/CompactHour'
import { Carousel, Slide } from 'vue-carousel'
import Axios from 'axios'

export default {
  name: "ScheduleCarousel",
  components: {
    CompactData,
    CompactHour,
    Carousel,
    Slide
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('schedule', ['date', 'hour', 'environment']),
    ...mapGetters('property', ['availablehours', 'property']),
    possibleDates() {
      return [0, 1, 2, 3, 4, 5, 6]
    }
  },
  methods: {
    ...mapMutations('schedule', ['setDate', 'setHour', 'setScheduleId']),
    ...mapMutations('property', ['setAvailablehours']),
    getDateDayByOffset (offset) {
      let today = new Date()
      today.setDate(today.getDate() + offset)
      return today.getDate()
    },
    getDateByOffset (offset) {
      let today = new Date()
      today.setDate(today.getDate() + offset)

      let dd = today.getDate()
      let mm = today.getMonth() + 1
      let yyyy = today.getFullYear()

      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      let dateString = yyyy + '-' + mm + '-' + dd

      return dateString
    },
    getWeekDayByOffset( offset) {
      let days = [
        'DOMINGO',
        'SEGUNDA',
        'TERÇA',
        'QUARTA',
        'QUINTA',
        'SEXTA',
        'SÁBADO'
      ]
      let day = new Date().getDay() + offset
      if (new Date().getDay() + offset > 6) {
        day = new Date().getDay() + offset - 7
      }
      return days[day]
    },
    isDateActive (id) {
      return id === this.date
    },
    isHourActive (id) {
      return id === this.hour
    },
    getCurrentMonth () {
      const monthNames = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]

      if (this.date && this.date !== '') {
        return monthNames[parseInt(this.date.substr(5, 2)) - 1]
      } else {
        return monthNames[parseInt(new Date().getMonth())]
      }
    },
    getCurrentYear () {
      if (this.date && this.date !== '') {
        return this.date.substr(0, 4)
      } else {
        return new Date().getFullYear()
      }
    },
    getDateHours (date, event) {
      if (!this.isLoading && this.date !== date) {
        this.isLoading = true
        this.setDate(event)
        const propertycode = this.property.propertycode
        let activeExternalCompany = this.property.activeExternalCompany
        let companycode = this.property.companycode
        let url = ''
        if (activeExternalCompany) {
          url = `https://${this.environment}.ruadois.com.br/api/v1/companies/${companycode}/properties/external/${propertycode}/calendar?date=${date}`
        } else {
          url = `https://${this.environment}.ruadois.com.br/api/v1/properties/${propertycode}/calendar?date=${date}`
        }
        Axios.get(url)
          .then(async (response) => {
            if(response.status === 200) {
              let availablehours = await this.sortAvailableHours(response.data)
              await this.setAvailablehours(availablehours)
              this.isLoading = await false
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    sortAvailableHours (hours) {
      let availableHours = hours.filter(hour => {
        return hour.availability
      })
      let sortedAvailableHours = availableHours.sort((a, b) => {
        return new Date(a.datetime) - new Date(b.datetime);
      })
      
      return sortedAvailableHours
    },
    setDataHour (availablehour) {
      this.setHour(availablehour.datetime)
      this.setScheduleId(availablehour.id)
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";

.scheduleform {
  &__period {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #888888;
    margin: 15px 0px;
  }
  &__hourcarousel {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    @include respond-to(phone-only) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__warning {
    margin: 50px 60px;
    padding: 7px;
    border-radius: 5px;
    color: white;
    text-align: center;
    background: rgba(0,0,0,.3);
    font-weight: bold;
  }
  &__footerconfirm {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background: #e0e0e0;
    border-radius: 5px;
    line-height: 52px;
    padding: 0px 49px;
    float: right;
    cursor: not-allowed;
    transition: all 0.1s ease-out;
  }
  &__footerconfirm--active {
    background: var(--bg-color);
    color: #ffffff;
    transition: all 0.1s ease-out;
    cursor: pointer;
  }
  .card-day {
    margin: 0 4px;
    .loading-card {
      text-align: center;
      border: 1px solid #eee;
      border-radius: 5px;
      cursor: default;
      -webkit-transition: all .1s ease-out;
      transition: all .1s ease-out;
      height: 56px;
      .spinner {
        color: black;
        text-align: center;
        margin-top: 4.5px;
        width: 40px;
      }
    }
  }

  .VueCarousel-navigation-button {
    font-size: 21px !important;
    color: #999999 !important;
  }
  .VueCarousel-navigation--disabled {
    color: rgba(153, 153, 153, 0.25) !important;
  }
  .VueCarousel-navigation-button {
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    color: #000;
    text-decoration: none;
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
  .VueCarousel-navigation-button:focus {
    outline: 1px solid lightblue;
  }
  .VueCarousel-navigation-next {
    right: 0;
    transform: translateY(-50%) translateX(100%);
    font-family: "system";
  }
  .VueCarousel-navigation-prev {
    left: 0;
    transform: translateY(-50%) translateX(-100%);
    font-family: "system";
  }
  .VueCarousel-navigation--disabled {
    opacity: 0.5;
    cursor: default;
  }
  .VueCarousel-pagination {
    width: 100%;
    text-align: center;
  }
  .VueCarousel-pagination--top-overlay {
    position: absolute;
    top: 0;
  }
  .VueCarousel-pagination--bottom-overlay {
    position: absolute;
    bottom: 0;
  }
  .VueCarousel-dot-container {
    display: inline-block;
    margin: 0 auto;
    padding: 0;
  }
  .VueCarousel-dot {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: none;
    background-clip: content-box;
    box-sizing: content-box;
    padding: 0;
    border-radius: 100%;
    outline: none;
  }
  .VueCarousel-dot:focus {
    outline: 1px solid lightblue;
  }
  .VueCarousel-slide {
    flex-basis: inherit;
    flex-grow: 0;
    flex-shrink: 0;
    user-select: none;
    backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }
  .VueCarousel-slide-adjustableHeight {
    display: table;
    flex-basis: auto;
    width: 100%;
  }
  .VueCarousel {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .VueCarousel--reverse {
    flex-direction: column-reverse;
  }
  .VueCarousel-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .VueCarousel-inner {
    display: flex;
    flex-direction: row;
    backface-visibility: hidden;
  }
  .VueCarousel-inner--center {
    justify-content: center;
  }
}
</style>
