import { setPath } from '@/js_helpers/_vuex-helpers'
export const state = {
  schedule: {
    date: '',
    hour: '',
    scheduleId: '',
    environment: '',
    origin: ''
  }
}

export const getters = {
  schedule: store => store.schedule,
  date: store => store.schedule.date,
  hour: store => store.schedule.hour,
  scheduleId: store => store.schedule.scheduleId,
  environment: store => store.schedule.environment,
  origin: store => store.schedule.origin
}

export const mutations = {
  setDate: setPath(['schedule', 'date']),
  setHour: setPath(['schedule', 'hour']),
  setScheduleId: setPath(['schedule', 'scheduleId']),
  setEnvironment: setPath(['schedule', 'environment']),
  setOrigin: setPath(['schedule', 'origin'])
}

export const actions = {

}
