<template>
  <div>
    <h2 class="scheduleform__concludeheader">
      Ótimo! Até lá.
    </h2>
    <p class="scheduleform__concludedescription">
      Caso ocorra algum imprevisto, basta nos dizer.
      <br />
      Muito obrigado!
    </p>
    <parent>
      <child slot="footer">
        <a class="scheduleform__footerconclude"
          v-on:click="redirectToBegin()"
        >
          Concluir
        </a>
      </child>
    </parent>
  </div>
</template>
<script>
import store from '../../stores/store'

export default {
  name: 'ScheduleObrigado',
  store,
  methods: {
    redirectToBegin () {
      store.state.openModal = false
      store.state.scheduleStep = 'schedule'
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .scheduleform{
    &__concludeimage{
      width: 80px;
      display: block;
      margin: auto;
    }
    &__concludeheader{
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 38px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #444444;
    }
    &__concludedescription{
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 20px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #888888;
      margin-bottom: 52px;
    }
    &__footerconclude{
      display: block;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 14px;
      text-align: center;
      color: #444444;
      border-top: 2px solid #DDDDDD;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 10px;
      cursor: pointer;
    }
  }
</style>
