<template>
  <div class="compact-data" :class="{'compact-data--selected': isSelected, 'compact-data--blocked': !isAvailable}" :id="id" @click="$emit('click', id)">
    <div class="compact-data__label">{{label}}</div>
    <div class="compact-data__value">{{value}}</div>
  </div>
</template>
<script>
export default {
  name: 'compact-data',
  props: {
    label: String,
    value: String,
    isSelected: {
      type: Boolean,
      default: false
    },
    isAvailable: {
      type: Boolean,
      default: false
    },
    id: String
  },
  methods: {
    setClickEvent () {
      this.isSelected = true
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  @import "~@/styles/variables.scss";
  @import "~@/styles/mixins.scss";
  @import "~@/styles/main.scss";

.compact-data {
  text-align: center;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 0px;
  cursor: pointer;
  margin: 0px;
  transition: all 0.1s ease-out;
  &__label {
    @extend %typo-compact-data-label;
    text-align: center;
    display: block;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
  }
  &__value {
    @extend %typo-compact-data-value;
    margin-left: auto;
    text-align: center;
    font-weight: bold;
    line-height: 18px;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
  }
}
.compact-data.compact-data--blocked{
  background: #a49d9d;
  cursor: not-allowed;
}
.compact-data.compact-data--selected{
  background: var(--bg-color);
  transition: all 0.1s ease-out;
}
.compact-data.compact-data--selected .compact-data__label, .compact-data.compact-data--selected .compact-data__value, .compact-data.compact-data--blocked .compact-data__label, .compact-data.compact-data--blocked .compact-data__value{
  color: #ffffff;
}
</style>
