<template>
    <div class="compact-hour" :class="{'compact-hour--selected': isSelected, 'compact-hour--blocked': !isAvailable}" @click="$emit('click', value)">
        <div class="compact-data__hour">{{value}}</div>
    </div>
</template>
<script>
export default {
  name: 'compact-hour',
  props: {
    value: String,
    isSelected: {
      type: Boolean,
      default: false
    },
    isAvailable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  @import "~@/styles/variables.scss";
  @import "~@/styles/mixins.scss";
  @import "~@/styles/main.scss";

.compact-hour {
  text-align: center;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 62px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
  &__value {
    @extend %typo-compact-data-value;
    margin-left: auto;
    text-align: center;
    font-weight: bold;
    line-height: 18px;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
  }
}
.compact-hour.compact-hour--blocked{
  background: #a49d9d;
  cursor: not-allowed;
}
.compact-hour.compact-hour--selected{
  background: var(--bg-color);
  transition: all 0.1s ease-out;
}
.compact-hour .compact-data__hour{
  color: #000000 !important;
}
.compact-hour.compact-hour--selected .compact-data__hour, .compact-hour.compact-hour--blocked .compact-data__hour{
  color: #ffffff !important;
}
</style>
