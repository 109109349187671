import { getField, updateField } from 'vuex-map-fields'
import Axios from 'axios'

export const state = {
  user: {
    name: '',
    email: '',
    phone: '',
    userId: ''
  },
  auth: {
    smsToken: '',
    serverToken: ''
  }
}

export const getters = {
  getField
}

export const mutations = {
  updateField,
  setServerToken (store, payload) {
    store.auth.serverToken = payload
  }
}

function cleanMaskFromNumbers (str) {
  return str.replace(/\D+/g, '')
}

export const actions = {
  saveUser (context) {
    let URL = 'https://a0zni0wo8d.execute-api.sa-east-1.amazonaws.com/prod/params'
    let phone = `+55${cleanMaskFromNumbers(context.getters.getField('user.phone'))}`

    let DATA = {
      phone,
      email: context.getters.getField('user.email'),
      nameLead: context.getters.getField('user.name')
    }
    return Axios.post(URL, DATA)
      .then((response) => {
        context.commit('setServerToken', response.data)
        return response
      })
  },
  authenticateUser (context) {
    let URL = 'https://2g2ir8i94d.execute-api.sa-east-1.amazonaws.com/prod/params'
    let DATA = {
      uuidOwnerLead: context.getters.getField('auth.serverToken'),
      code: context.getters.getField('auth.smsToken')
    }
    return Axios.post(URL, DATA)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw Error(error)
      })
  }
}
