<template>
<router-link v-if="route" :to="to"
             @click="triggerEvent"
             class="button"
             :class="[{'button--secondary': secondary}, {'button--block': block}, {'button--disabled': disabled}, 'button--' + size]">
  <slot></slot>
  <span class="button__arrow" v-if="arrow"></span>
</router-link>
<a :url="to"
   v-else
   @click="triggerEvent"
   class="button"
   :class="[{'button--loading': isLoading}, {'button--secondary': secondary}, {'button--block': block}, {'button--disabled': disabled}, 'button--' + size]">
  <slot></slot>
  <span class="button__arrow" v-if="arrow"></span>
</a>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    to: {
      type: String,
      default: '#'
    },
    secondary: {
      type: Boolean,
      default: false
    },
    route: {
      type: Boolean,
      default: false
    },
    arrow: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    triggerEvent () {
      if (!this.disabled) { this.$emit('click') }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "~@/styles/variables.scss";
  @import "~@/styles/mixins.scss";
  @import "~@/styles/main.scss";

.button {
  @extend %typo-label; //TODO: button medium
  @extend %prevent-selection;
  display: inline-block;
  line-height: $form-input-line-height;
  padding: $form-input-padding-v $form-input-padding-h;
  border-radius: $form-input-border-radius;
  background-color: var(--bg-color);
  box-sizing: border-box;
  border: 1px solid transparent;
  color: #fff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  &--loading {
    position: relative;
    @include adjust-opacity(brand-color, 0.3);
    background-color: var(--bg-color);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('~@/assets/img/general-loader.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &__arrow {
    display: inline-block;
    height: 14px;
    width: 18px;
    margin-left: 14px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(~@/assets/img/arrow-white.svg);
  }
  &--mid {
    padding: 20px 20px;
    @include respond-to(phone-only) {
      padding: 10px 10px;
      font-size: 1.15rem;
    }
  }
  &--block {
    display: block;
  }
  &--disabled {
    background-color: rgba(black, 0.3);
    color: rgba(white, 0.5);
    cursor: not-allowed;
    /*pointer-events: none;*/
  }
  &--secondary {
    background-color: transparent;
    border-color: var(--bg-color);
    color: var(--bg-color);
  }
  &--secondary & {
    &__arrow {
      background-image: url(~@/assets/img/arrow-blue.svg);
    }
  }
}
</style>
