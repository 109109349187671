import { setPath } from '@/js_helpers/_vuex-helpers'
export const state = {
  property: {
    availablehours: [],
    propertycode: '',
    company: '',
    companyname: '',
    activeExternalCompany: false
  }
}

export const getters = {
  property: store => store.property,
  availablehours: store => store.property.availablehours,
  propertycode: store => store.property.propertycode,
  companycode: store => store.property.companycode,
  activeExternalCompany: store => store.property.activeExternalCompany
}

export const mutations = {
  setAvailablehours: setPath(['property', 'availablehours']),
  setPropertyCode: setPath(['property', 'propertycode']),
  setCompanyCode: setPath(['property', 'companycode']),
  setActiveExternalCompany: setPath(['property', 'activeExternalCompany'])
}

export const actions = {

}
