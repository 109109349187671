<template>
  <div>
    <h2 class="scheduleform__header">{{this.userName}}, confirme sua visita</h2>
    <ScheduleCarousel />
    <div class="attention-block active">
      <h3>ATENÇÃO</h3>
      <div class="attention-inner-block">
        <div class="attention-icon">
          <i class="fa fas-ruler"></i>
        </div>
        <p>
          Certifique-se de que você realmente poderá 
          visitar o imóvel. Cancelamentos desnecessários 
          causam transtornos para todos os interessados.
        </p>
      </div>
      <div class="attention-inner-block">
        <div class="attention-icon">
          <i class="fa fas-ruler"></i>
        </div>
        <p>
          Sua visita será acompanhada por um corretor 
          e até 4 outros interessados.
        </p>
      </div>
    </div>
    <parent>
      <child slot="footer">
        <a
          class="scheduleform__footercancel"
          @click="redirectToBegin()"
        >
          Cancelar
        </a>
        <a
          class="scheduleform__footerconfirm scheduleform__footerconfirm--active"
          @click="sendRequest ()"
        >
          <i
            v-if="isLoading"
            class="fas fa-spinner fa-spin fa-2x spinner"
          />
          <span v-else>Confirmar visita</span>
        </a>
      </child>
    </parent>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import Axios from 'axios'
import ScheduleCarousel from './agendamento_sections/ScheduleCarousel'
import store from '../../stores/store' 

const { mapFields } = createHelpers({
  getterType: `user/getField`,
  mutationType: `user/updateField`
})

export default {
  name: 'ScheduleConfirmation',
  store,
  data() {
    return {
      isLoading: false
    };
  },
  components: {
    ScheduleCarousel
  },
  computed: {
    ...mapFields({
      userName: 'user.name',
      userPhone: 'user.phone',
      userEmail: 'user.email',
      userId: 'user.userId'
    }),
    ...mapGetters('schedule', ['date', 'hour', 'scheduleId', 'environment', 'origin']),
    ...mapGetters('property', ['companycode', 'propertycode', 'activeExternalCompany']),
  },
  methods: {
    ...mapActions('user', ['saveUser']),
    async sendRequest () {
      if (!this.isLoading) {
        this.isLoading = true;
        let url = ''
        console.log(this.activeExternalCompany);
        if (this.activeExternalCompany) {
          url = `https://${this.environment}.ruadois.com.br/api/v1/companies/${this.companycode}/properties/external/${this.propertycode}/visits`
        } else {
          const response = await Axios.get(`https://${this.environment}.ruadois.com.br/api/v1/properties/${this.propertycode}`);
          if (response && response.data) {
            url = `https://${this.environment}.ruadois.com.br/api/v1/companies/${response.data.companyId}/properties/${this.propertycode}/visits`
          }
        }

        let body = '';

        if (this.userId) {
          body = JSON.stringify({
            scheduleId: this.scheduleId,
            clientIdentifier: this.userId,
            origin: this.origin
          })
        } else {
          body = JSON.stringify({
            scheduleId: this.scheduleId,
            phone: this.userPhone,
            email: this.userEmail.text,
            name: this.userName,
            origin: this.origin
          })
        }


        let options = {
          headers: {
            'Content-Type': 'application/json'
          }
        }

        Axios.post(url, body, options)
          .then((response) => {
            this.isLoading = false
            if(!response.data.error) {
              store.state.scheduleStep = 'obrigado'
            } else {
              store.state.scheduleStep = 'error'
            }
          })
          .catch(error => {
            store.state.scheduleStep = 'error'
          })
      }
    },
    redirectToBegin () {
      store.state.openModal = false
      store.state.scheduleStep = 'schedule'
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.scheduleform {
  &__header {
    font-weight: bold;
    line-height: normal;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #444444;
    padding: 10px 0px;
    margin: 0;
  }
  &__period {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #888888;
    margin: 15px 0px;
  }
  &__hourcarousel {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__footercancel {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #888888;
    float: left;
    vertical-align: middle;
    display: inline-block;
    text-transform: capitalize;
    line-height: 52px;
    padding: 0px 49px;
  }
  &__footerconfirm {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background: #e0e0e0;
    border-radius: 5px;
    line-height: 52px;
    height: 52px;
    padding: 0px 49px;
    float: right;
    cursor: not-allowed;
    img {
      width: 30px;
      padding-top: 11px;
    }
  }
  &__footerconfirm--active {
    background: var(--bg-color);
    color: #ffffff;
    transition: all 0.1s ease-out;
    cursor: pointer;
    .spinner {
      margin-top: 10px;
      width: 144px;
    }
  }
}
.attention-block {
  border-radius: 20px;
  padding: 24px 35px;
  background: #f2f2f2;
  margin: 30px 0px;
}
.attention-block h3 {
  font-weight: bold;
  line-height: normal;
  font-size: 24px;
  letter-spacing: -0.03em;
  color: #444444;
  margin: 0;
}
.attention-block p {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #444444;
  margin: 0;
}
</style>
